import React from "react";
import Iframe from "react-iframe";

declare global {
  interface Window {
    fbAsyncInit: any;
    FB: any;
  }
  interface Element {
    src: string;
    async: boolean;
    defer: boolean;
  }
}

export default function FacebookWidget(props: any) {
  const { config } = props;
  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <iframe
        title={"Facebook"}
        src={config["link"]}
        style={{ position: "relative", border: "none" }}
        width={"340px"}
        height={"700px"}
      />
    </div>
  );
}

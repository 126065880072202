import React, {useState, useEffect} from 'react';
import ReactLoading from "react-loading";
import {Col, Row} from "reactstrap";
import Swiper from 'react-id-swiper';
import Paper from "@material-ui/core/Paper/Paper";
import Lightbox from "react-images";
import './OCVPage.css';

interface Props {
    classes: any;
    link: string;
    subtypes: any;
}

export default function OCVPage(props: Props) {
    const [pageData, setPageData] = useState<any>(null);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event: {preventDefault: () => void}, index: number) => {
        event.preventDefault();
        setCurrentImage(index);
        setLightboxIsOpen(true);
    };
    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };
    const gotoPrevious = () => {
        setCurrentImage(currentImage - 1);
    };
    const gotoNext = () => {
        setCurrentImage(currentImage + 1);
    };

    useEffect(() => {
        try {
            fetch(props.link)
                .then(response => response.json())
                .then(data => {
                    setPageData(data['data']);
                });
        } catch (e) {
            console.log(e);
        }
    }, [props.link]);

    let images: any[] = [];
    if (pageData !== null) {
        for (let i = 0; i < pageData.images.length; i++) {
            images.push({src: pageData.images[i].large});
        }
    }
    if (pageData === null) {
        return <div className='OCVPageExternalDiv'><ReactLoading className='loading-centered' type={"bars"} color={'#FFF'} height={'10%'} width={'10%'} /></div>;
    } else {
        const params = {
            parallax: true,
            slidesPerView: 1,
            speed: 3000,
            loop: (pageData["images"].length !== 1),
            autoplay: {
                disableOnInteraction: true
            },
            autoHeight: true
        };
        const {subtypes} = props;
        return (
            <>
                <Row>
                    {(subtypes.includes("imageAtTop") && pageData.images.length !== 0)
                        ? <Col className='OCVPageColOne' xs={10} sm={10} md={10}>
                            <Swiper {...params}>
                                {pageData.images.map((image: {large: string, small: string}, index: number) => (
                                    <div key={index}><img height={400} onClick={(e) => openLightbox(e, index)} alt={image.large} className='OCVPageImage' src={image.large} /></div>
                                ))}
                            </Swiper>
                            <Lightbox onClickNext={gotoNext} onClickPrev={gotoPrevious}
                                backdropClosesModal currentImage={currentImage}
                                onClose={closeLightbox} isOpen={lightboxIsOpen}
                                images={images}
                            />
                        </Col> : ""}
                    <Col className='OCVPageColTwo' xs={10}>
                        <Paper className='OCVPagePaper'>
                            <div className='OCVPageDiv' dangerouslySetInnerHTML={{__html: pageData.content}} />
                        </Paper>
                    </Col>
                    {(!subtypes.includes("imageAtTop") && pageData.images.length !== 0)
                        ? <Col className='OCVPageColOne' xs={10} sm={10} md={10}>
                            <Swiper {...params}>
                                {pageData.images.map((image: {large: string, small: string}, index: number) => (
                                    <div key={index}><img height={400} onClick={(e) => openLightbox(e, index)} alt={image.large} className='OCVPageImage' src={image.large} /></div>
                                ))}
                            </Swiper>
                            <Lightbox onClickNext={gotoNext} onClickPrev={gotoPrevious}
                                backdropClosesModal currentImage={currentImage}
                                onClose={closeLightbox} isOpen={lightboxIsOpen}
                                images={images}
                            />
                        </Col> : ""}
                </Row>
            </>
        );
    }
};